.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1000;
	pointer-events: all;
	background-color: rgba(0, 0, 0, .4);
}

.modal {
	position: absolute;
	top: 10%;
	left: 50%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	border-radius: 5px;
	padding: 20px;
    width: max-content;
	min-width: 20vw;
	max-width: calc(100vw - 20px);
	min-height: 40vh;
	max-height: 80vh;
	transform:translateX(-50%);
	background: rgba(255, 255, 255, .8);
}

.header {
	flex-shrink: 0;
	font-size: var(--huge);
	font-weight: bold;
}

.body {
	flex-grow: 1;
	overflow: auto;
}

.footer {
	display: flex;
	flex-shrink: 0;
	margin-top: 10px;
	margin-left: auto;
}

.footer > button:not(:last-child) {
	margin-right: 10px;
}

.label {
	font-size: var(--large);
	margin: 10px 0;
}

.button {
	display: block;
	border: 2px solid var(--carbon);
	border-radius: 5px;
	padding: 5px;
}

.radio-button {
	display: flex;
	align-items: center;
	margin: 2px 0 2px 10px;
}

.radio-circle {
	position: relative;
	margin-right: 5px;
	border: 2px solid var(--carbon);
	border-radius: 10px;
	width: 20px;
	height: 20px;
}
.radio-circle::before {
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 5px;
	width: 0;
	height: 0;
	background-color: var(--carbon);
	transition: width .1s, height .1s;
}
.selected::before {
	width: 10px;
	height: 10px;
}

.disclaimer {
	margin-top: 5px;
	font-size: var(--small);
	opacity: .8;
}
